import axios from '@axios'

const PREFIX = '/certificados-digitais'
const getFormDataBody = (file, body) => {
  const formData = new window.FormData()
  if (file && file.file) {
    formData.append('file', file.file, file.fileName || file.name)
  }
  // eslint-disable-next-line no-param-reassign
  body.file = null
  formData.append('body', JSON.stringify(body))

  return formData
}

export default {

  salvarCertificado(certificado) {
    if (certificado.arquivo && certificado.arquivo.file) {
      const body = getFormDataBody(certificado.arquivo, certificado)
      return axios.post(`${PREFIX}`, body)
    }
    return axios.post(`${PREFIX}`, certificado)
  },

  alterarCertificado(id, certificado) {
    if (certificado.arquivo && certificado.arquivo.file) {
      const body = getFormDataBody(certificado.arquivo, certificado)
      return axios.put(`${PREFIX}/${id}`, body)
    }
    return axios.put(`${PREFIX}/${id}`, certificado)
  },

  cadastrarEmpresaPorCertificado(id) {
    return axios.get(`${PREFIX}/${id}/cadastrarEmpresas`)
  },

  removerCertificado(ids) {
    if ((Array.isArray(ids) && ids.length > 1)) {
      return axios.delete(`${PREFIX}/lote?valores=[${ids}]`)
    }
    return axios.delete(`${PREFIX}/${ids}`)
  },

  getPaginado(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  getCertificado(id) {
    return axios.get(`${PREFIX}/${id}`)
  },

  getCertificadosResumidos(filtro) {
    return axios.post(`${PREFIX}/resumidos`, filtro)
  },

  getCertificadosResumidosValidos(filtro) {
    return axios.post(`${PREFIX}/resumidos/validos`, filtro)
  },

  getEmpresasPorCertificado(id, filtro) {
    return axios.post(`${PREFIX}/${id || 0}/empresas?`, filtro)
  },

  getEmpresasParaVinculo() {
    return axios.get(`${PREFIX}/empresas`)
  },

  getCertificadoVencido() {
    return axios.get(`${PREFIX}/certificado-vencido`)
  },

  alterarResponsavelLegal(empresaId, responsavelLegal) {
    return axios.put(`${PREFIX}/reponsavel-legal?empresaId=${empresaId}&responsavelLegal=${responsavelLegal}`)
  },
  baixarEmExcel(filtros) {
    return axios.post(`${PREFIX}/baixa-excel`, filtros)
  },

  deletarExcel(url, nomeArquivo) {
    axios.post(`${PREFIX}/deletar-excel?url=${url}&nomeArquivo=${nomeArquivo}`)
  },
}
